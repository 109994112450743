<template>
  <span class="app-link primary--text">
    <router-link v-if="to" :to="to">
      <slot></slot>
    </router-link>
    <span v-else>
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    to: {
      default: null,
      required: false,
    },
  },
};
</script>

<style scoped>
.app-link {
  cursor: pointer;
  text-decoration: underline;
}
</style>

