<template>
  <div class="page-container">

    <Toolbar title="ユーザーの一覧" tabs>
      <v-layout v-if="$store.state.auth.roles.admin" slot="extension" justify-end>
        <v-btn outline color="primary" small @click="open">ユーザを追加</v-btn>
      </v-layout>
    </Toolbar>

    <v-layout row>
      <v-flex xs12>
        <v-list two-line>

          <template v-if="initialized.users && users.length === 0">
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon class="accent" dark>mdi-account</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>
                  登録済みのユーザはいません
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <div v-else-if="initialized.users">
            <transition-group name="list" tag="div" appear>
              <template v-for="(item, index) in users">
                <v-list-tile avatar :key="item.id">
                  <v-list-tile-avatar>
                    <v-icon :class="!!item.data.created?'accent':'grey lighten-1'" dark>mdi-account</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{item.data.name}}
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      <v-icon small class="mr-1">mdi-contact-mail</v-icon>{{item.data.email}}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <span v-if="item.data.roles.admin">
                      管理者
                    </span>
                    <span v-else-if="item.data.roles.editor">
                      メンバー
                      <v-btn v-if="$store.state.auth.roles.admin" flat icon @click="remove(item)" :disabled="disabled">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </span>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider :key="index"></v-divider>
              </template>
            </transition-group>
          </div>

        </v-list>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialog" persistent :fullscreen="!$vuetify.breakpoint.lgAndUp" max-width="1000px">
      <v-card>
        <v-toolbar card>
          <v-btn icon @click="dialog = false" :disabled="disabled">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn flat @click="add" :disabled="disabled">
            保存
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-4">
          <v-alert v-for="(error,index) in errors" :key="index" color="error" icon="mdi-alert" :value="true">
            {{error}}
          </v-alert>
          <v-form ref="form" class="py-2">
            <v-layout wrap>
              <v-flex xs12>
                <AppLabel required>名前</AppLabel>
                <v-text-field v-model.trim="data.name" :rules="rules.name" :counter="50" label="名前" solo :disabled="disabled" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <AppLabel required>Gmailアドレス</AppLabel>
                <v-text-field v-model.trim="data.email" :rules="rules.email" :counter="50" label="Gmail" solo :disabled="disabled" required></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.active" :color="snackbar.color">
      {{ snackbar.text }}
      <v-btn dark flat icon @click="snackbar.active = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import Firebase from '@/mixins/firebase';
import Toolbar from '@/components/Toolbar.vue';
import AppLabel from '@/components/AppLabel.vue';

export default {
  mixins: [Firebase],
  components: { Toolbar, AppLabel },
  data() {
    return {
      ref: null,
      users: [],
      initialized: { users: false },
      // add
      dialog: false,
      disabled: false,
      errors: [],
      data: {},
      rules: {
        name: [
          v => !!v || '必須です',
          v => (v && v.length <= 50) || '50文字以下にして下さい',
        ],
        email: [
          v => !!v || '必須です',
          v => (v && v.length <= 50) || '50文字以下にして下さい',
          v => {
            const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            return pattern.test(v) || 'メールアドレスを入力して下さい';
          },
        ],
      },
      snackbar: { active: false, text: '', color: null },
    };
  },
  created() {
    this.ref = this.db
      .collection('teams')
      .doc(this.$store.state.auth.domain)
      .collection('users');
    this.listen();
  },
  methods: {
    listen() {
      this.unsubscribes.push(
        this.ref.orderBy('invited', 'asc').onSnapshot(
          querySnapshot => {
            querySnapshot.docChanges().forEach(change => {
              const { doc, type } = change;
              const user = {
                path: doc.ref.path,
                id: doc.id,
                data: doc.data(),
              };
              if (type === 'removed' || type === 'modified') {
                this.users.splice(change.oldIndex, 1);
              }
              if (type === 'added' || type === 'modified') {
                this.users.splice(change.newIndex, 0, user);
              }
            });
            // this.sites = sites;
            if (!this.initialized.users) this.initialized.users = true;
          },
          error => {
            // eslint-disable-next-line
            console.log(error);
          }
        )
      );
    },
    open() {
      this.errors = [];
      this.data = { email: '', name: '' };
      this.$refs.form.reset();
      this.dialog = true;
    },
    add() {
      // clean up
      this.errors = [];
      // validate
      if (!this.$refs.form.validate()) {
        this.errors.push('入力内容を確認してください');
        return;
      }
      // ui effect
      this.disabled = true;
      // prepare datas
      const { db, data } = this;
      const roles = { roles: { editor: true } };
      const docRef = this.ref.doc();
      const uniqueRef = db
        .collection('users')
        .doc(data.email)
        .collection('teams')
        .doc(this.$store.state.auth.domain);
      db.runTransaction(transaction =>
        transaction.get(uniqueRef).then(doc => {
          if (doc.exists)
            throw new Error('このメールアドレスはすでに使われています');
          transaction.set(
            docRef,
            Object.assign({}, data, roles, {
              invited: this.firebase.firestore.FieldValue.serverTimestamp(),
            })
          );
          transaction.set(
            uniqueRef,
            Object.assign({}, roles, { idInTeam: docRef.id })
          );
        })
      )
        .then(() => {
          this.dialog = false;
          this.snackbar.text = '保存しました';
          this.snackbar.color = 'success';
          this.snackbar.active = true;
        })
        .catch(error => {
          this.errors.push(error.message || '予期せぬエラーが起きました。');
          this.snackbar.text = '保存に失敗しました';
          this.snackbar.color = 'error';
          this.snackbar.active = true;
        })
        .then(() => {
          this.disabled = false;
        });
    },
    remove(item) {
      if (!item.id) return;
      // eslint-disable-next-line
      const result = window.confirm(`「${item.data.name}」を削除しますか？`);
      if (!result) return;
      // ui effect
      this.disabled = true;
      // delete
      const docRef = this.ref.doc(item.id);
      const uniqueRef = this.db
        .collection('users')
        .doc(item.data.email)
        .collection('teams')
        .doc(this.$store.state.auth.domain);
      const batch = this.db.batch();
      batch.delete(docRef);
      batch.delete(uniqueRef);
      batch
        .commit()
        .then(() => {
          this.snackbar.text = '削除しました';
          this.snackbar.color = 'success';
          this.snackbar.active = true;
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error);
          this.errors.push(error.message || '予期せぬエラーが起きました。');
          this.snackbar.text = '削除に失敗しました';
          this.snackbar.color = 'error';
          this.snackbar.active = true;
        })
        .then(() => {
          this.disabled = false;
        });
    },
  },
};
</script>
