<template>
  <v-toolbar flat :tabs="tabs">
    <v-btn v-if="!top" flat icon @click="back">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-toolbar-title class="subheading">
      <v-layout column align-center>
        <v-flex class="font-weight-bold">
          {{title}}
        </v-flex>
        <v-flex v-if="subtitle" class="caption">
          {{subtitle}}
        </v-flex>
      </v-layout>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <slot name="actions"></slot>
    <slot name="extension" slot="extension"></slot>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      required: false,
    },
    tabs: {
      type: Boolean,
      required: false,
    },
    top: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      map: {
        Pages: null,
        Users: { name: 'Pages' },
        Page: { name: 'Pages' },
        Profile: { name: 'Pages' },
        Settings: { name: 'Pages' },
      },
    };
  },
  methods: {
    back() {
      const from = this.$route.name;
      if (!from) return;
      const to = this.map[from];
      if (!to) return;
      this.$router.push(to);
    },
  },
};
</script>

<style scoped>
.v-btn {
  min-width: 0;
}
.v-btn.actions:last-child {
  margin-right: -16px;
}
@media only screen and (max-width: 959px) {
  .v-toolbar >>> .v-toolbar__extension {
    padding: 0;
  }
}
</style>

