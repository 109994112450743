<template>
  <div class="page-container">

    <Toolbar title="チーム設定" :subtitle="`@${$store.state.auth.domain}`"></Toolbar>

    <v-container fluid grid-list-lg>
      <v-alert v-for="(error,index) in errors" :key="index" color="error" icon="mdi-alert" :value="true">
        {{error}}
      </v-alert>

      <v-form ref="form">
        <v-layout row wrap class="ma-0">

        </v-layout>
      </v-form>

      <v-snackbar v-model="snackbar.active" :color="snackbar.color">
        {{ snackbar.text }}
        <v-btn dark flat icon @click="snackbar.active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>

    </v-container>

  </div>
</template>

<script>
import Firebase from '@/mixins/firebase';
import Toolbar from '@/components/Toolbar.vue';
import AppLabel from '@/components/AppLabel.vue';

export default {
  mixins: [Firebase],
  components: { Toolbar, AppLabel },
  data() {
    return {
      disabled: false,
      ref: null,
      initialized: { settings: false },
      data: {},
      errors: [],
      newData: {},
      rules: {},
      hints: {},
      snackbar: { active: false, text: '', color: null },
    };
  },
  created() {
    this.ref = this.db.collection('teams').doc(this.$store.state.auth.domain);
    this.listen();
  },
  watch: {
    data: {
      // eslint-disable-next-line
      handler(data) {
        this.newData = {};
      },
      immediate: true,
    },
  },
  methods: {
    listen() {
      this.unsubscribes.push(
        this.ref.onSnapshot(
          doc => {
            this.data = doc.exists ? doc.data() : {};
            if (!this.initialized.settings) this.initialized.settings = true;
          },
          error => {
            // eslint-disable-next-line
            console.log(error);
          }
        )
      );
    },
    save() {
      // clean up
      this.errors = [];
      // validate
      if (!this.$refs.form.validate()) {
        this.errors.push('入力内容を確認してください');
        return;
      }
      // ui effect
      this.disabled = true;
      // prepare datas
      this.ref
        .set(this.newData, { merge: true })
        .then(() => {
          this.dialog = false;
          this.snackbar.text = '保存しました';
          this.snackbar.color = 'success';
          this.snackbar.active = true;
        })
        .catch(error => {
          this.errors.push(error.message || '予期せぬエラーが起きました。');
          this.snackbar.text = '保存に失敗しました';
          this.snackbar.color = 'error';
          this.snackbar.active = true;
        })
        .then(() => {
          this.disabled = false;
        });
    },
  },
};
</script>

