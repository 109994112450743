<template>
  <div>
    <v-card class="mb-2">
      <v-layout row wrap class="ma-0">
        <v-flex v-for="(item,index) in data" :key="index" xs4 md3>
          <v-card flat tile>
            <v-img :src="item.src||''" :aspect-ratio="4/3" contain class="grey lighten-2" @error="item.doc?loadImage($event, item.doc.ref.path):''" :key="`${index}-${item.name}`">
              <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                <v-progress-circular indeterminate color="white"></v-progress-circular>
              </v-layout>
              <v-layout justify-end>
                <v-btn flat icon color="primary" @click="remove(index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-layout>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>

      <div class="ma-2 pb-2">
        <v-btn @click="$refs.file.click()" depressed color="primary" class="ml-0" :disabled="disabled" :loading="loading">アップロード</v-btn>
        <input ref="file" type="file" :key="resetKey" :accept="accept" :disabled="disabled" @change="onChange()" style="display:none;">
      </div>
    </v-card>
    <div class="v-text-field__details">
      <div class="v-messages theme--light">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">{{hint}}</div>
        </div>
      </div>
      <div class="v-counter theme--light">{{data.length}} / {{limit}}</div>
    </div>
  </div>
</template>

<script>
import Firebase from '@/mixins/firebase';

export default {
  mixins: [Firebase],
  props: {
    images: {
      type: Array,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      default: 'image.*',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    limit: {
      type: Number,
      required: true,
    },
    hint: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      removed: [],
      resetKey: 0,
    };
  },
  watch: {
    images: {
      handler() {
        this.resetKey += 1;
        if (!this.loading) this.init();
      },
      immediate: true,
    },
    loading: {
      handler(loading) {
        if (!loading) this.init();
      },
    },
  },
  methods: {
    init() {
      const data = this.images.map((doc, index) => {
        const src = doc.get('src');
        return {
          name: src,
          src,
          saved: true,
          index,
          doc,
          file: null,
        };
      });
      this.data = data;
    },
    onChange() {
      if (Object.keys(this.data).length >= this.limit) {
        // eslint-disable-next-line
        alert(`画像の上限は${this.limit}枚までです。`);
        return;
      }
      const file = this.$refs.file.files[0];
      if (!file) return;
      if (!file.type.match(this.accept)) {
        // eslint-disable-next-line
        alert('画像を選択してください。');
        return;
      }
      if (file.size >= 10 * 1024 * 1024) {
        // eslint-disable-next-line
        alert('画像サイズが大きすぎます。10MB以下にしてください。');
        return;
      }
      // read
      const reader = new FileReader();
      reader.onload = () => {
        this.add(reader.result, file);
      };
      reader.readAsDataURL(file);
    },
    add(src, file) {
      this.data.push({
        name: file.name,
        src,
        saved: false,
        index: null,
        doc: null,
        file,
      });
    },
    remove(index) {
      if (!this.data[index]) return;
      const removed = this.data.splice(index, 1);
      removed.forEach(image => {
        if (image.saved) this.removed.push(image);
      });
    },
    upload() {
      this.loading = true;
      const { data, path, storage } = this;
      const promises = [];
      data.forEach((image, index) => {
        // saved and reflected
        if (image.saved) {
          if (index === image.index) return;
          promises.push(image.doc.ref.update({ index }));
          return;
        }
        // not saved
        if (!image.file) throw new Error('ファイルが見つかりません');
        // has new data
        const name = this.uid(20);
        const promise = this.db
          .doc(path)
          .collection('images')
          .doc(name)
          .set({ index, src: null })
          .then(
            () =>
              new Promise(resolve => {
                const uploadTask = storage
                  .ref(`tmp/${this.$store.state.auth.domain}/${name}`)
                  .put(image.file, { customMetadata: { path, index } });
                // watch upload
                uploadTask.on('state_changed', {
                  complete: () => {
                    // eslint-disable-next-line
                    console.log('upload complete!');
                    resolve('画像のアップロードに成功しました');
                  },
                  error: () => {
                    // eslint-disable-next-line
                    console.log(error);
                    throw new Error('画像のアップロードに失敗しました');
                  },
                });
              })
          );
        promises.push(promise);
      });
      this.removed.forEach(image => {
        if (image.saved) {
          promises.push(image.doc.ref.delete());
        }
      });
      return Promise.all(promises)
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          return Promise.reject(error);
        });
    },
  },
};
</script>

<style scoped>
.v-text-field__details {
  margin-bottom: 8px;
  padding: 0 12px;
}
</style>
