import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import view from './view';
import env from './env';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    view,
    env,
  },
});

store.watch(
  state => state.auth.user,
  user => {
    store.dispatch('auth/listen', { user });
  }
);

export default store;
