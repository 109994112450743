import firebase from '@/vendor/firebase';
import moment from '@/vendor/moment';

export default {
  data() {
    return {
      unsubscribes: [],
      firebase,
      db: firebase.firestore(),
      storage: firebase.storage(),
      moment,
    };
  },
  methods: {
    loadImage(e, path) {
      const url = e;
      if (!url) return;
      this.storage
        .refFromURL(url)
        .getDownloadURL()
        // save url to firestore
        .then(downloadURL =>
          this.db.doc(path).update({
            src: downloadURL,
          })
        )
        .catch(error => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      return moment(timestamp.toMillis())
        .tz('Asia/Tokyo')
        .format('YYYY年MM月DD日 k時mm分');
    },
    uid(count) {
      // default count was 20
      const chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let autoId = '';
      for (let i = 0; i < count; i += 1) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return autoId;
    },
    unsubscribe() {
      this.unsubscribes.forEach(unsubscribe => {
        if (typeof unsubscribe === 'function') unsubscribe();
      });
      this.unsubscribes = [];
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
