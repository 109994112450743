import moment from 'moment-timezone/moment-timezone';

const data = {
  version: '2018e',
  zones: [
    'Asia/Tokyo|JST JDT|-90 -a0|010101010|-QJJ0 Rb0 1ld0 14n0 1zd0 On0 1zd0 On0|38e6',
  ],
  links: ['Asia/Tokyo|Japan'],
};
moment.tz.load(data);

export default moment;
