<template>
  <div id="base">
    <v-app>
      <transition name="fade" v-if="inprogress">
        <v-progress-linear background-color="deep-purple lighten-4" :indeterminate="inprogress" value="100"></v-progress-linear>
      </transition>

      <transition name="fade" v-if="authenticated">
        <router-view></router-view>
      </transition>

      <v-content v-else>
        <v-container fluid fill-height>
          <v-layout column align-center justify-center class="text-xs-center">
            <h3 class="display-3">Now Loading</h3>
            <span class="subheading">Please wait a moment.</span>
          </v-layout>
        </v-container>
      </v-content>

    </v-app>
  </div>
</template>

<script>
export default {
  created() {
    return this.$store.commit('view/inprogress', true);
  },
  beforeDestroy() {
    if (this.inprogress) this.$store.commit('view/inprogress', false);
  },
  computed: {
    inprogress() {
      return this.$store.state.view.inprogress;
    },
    authenticated() {
      return this.$store.state.auth.authenticated;
    },
  },
  watch: {
    authenticated(val) {
      if (val) this.$store.commit('view/inprogress', false);
    },
  },
};
</script>

<style scoped>
.v-progress-linear {
  margin: 0;
  position: fixed;
  z-index: 5;
}
</style>