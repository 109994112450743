<template>
  <div class="page-container">

    <Toolbar title="ページの一覧" tabs top>
      <v-layout slot="extension" align-center>
        <v-tabs v-model="tab" color="transparent">
          <v-tab href="#all">
            すべて
          </v-tab>
          <v-tab href="#public">
            公開済み
          </v-tab>
          <v-tab href="#private">
            未公開
          </v-tab>
        </v-tabs>
        <v-btn v-if="!$vuetify.breakpoint.lgAndUp" outline color="primary" small :to="{ name: 'Page', params: { pageId: 'new' }}">ページを追加</v-btn>
      </v-layout>
    </Toolbar>

    <v-layout row>
      <v-flex xs12>
        <v-list>

          <template v-if="initialized.pages && pages.length === 0">
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon class="accent" dark>mdi-qrcode-scan</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>
                  登録済みのページはありません
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <div v-else-if="initialized.pages">
            <transition-group name="list" tag="div" appear>
              <template v-for="(item, index) in pages">
                <v-list-tile avatar :key="item.id" :to="{name:'Page', params:{pageId:item.id}}">
                  <v-list-tile-avatar>
                    <v-icon :class="item.data.public?'accent':'grey lighten-1'" dark>mdi-qrcode-scan</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{item.data.title}}
                    </v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider :key="index"></v-divider>
              </template>
            </transition-group>
          </div>

        </v-list>
      </v-flex>
    </v-layout>

  </div>
</template>

<script>
import Firebase from '@/mixins/firebase';
import Toolbar from '@/components/Toolbar.vue';

export default {
  mixins: [Firebase],
  components: { Toolbar },
  data() {
    return {
      tab: null,
      ref: null,
      pages: [],
      initialized: { pages: false },
    };
  },
  watch: {
    tab: {
      handler(tab) {
        if (!tab) return;
        this.ref = this.db
          .collection('pages')
          .where('team', '==', this.$store.state.auth.domain);
        if (tab === 'public') this.ref = this.ref.where('public', '==', true);
        if (tab === 'private') this.ref = this.ref.where('public', '==', false);
        this.unsubscribe();
        this.pages = [];
        this.initialized.pages = false;
        this.listen();
      },
      immediate: true,
    },
  },
  methods: {
    listen() {
      this.unsubscribes.push(
        this.ref.orderBy('created', 'desc').onSnapshot(
          querySnapshot => {
            querySnapshot.docChanges().forEach(change => {
              const { doc, type } = change;
              const page = {
                path: doc.ref.path,
                id: doc.id,
                data: doc.data(),
              };
              if (type === 'removed' || type === 'modified') {
                this.pages.splice(change.oldIndex, 1);
              }
              if (type === 'added' || type === 'modified') {
                this.pages.splice(change.newIndex, 0, page);
              }
            });
            // this.sites = sites;
            if (!this.initialized.pages) this.initialized.pages = true;
          },
          error => {
            // eslint-disable-next-line
            console.log(error);
          }
        )
      );
    },
  },
};
</script>
