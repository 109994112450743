<template>
  <div class="page-container">

    <Toolbar title="プロフィール" :subtitle="data.name"></Toolbar>

    <v-container fluid grid-list-lg>
      <v-alert v-for="(error,index) in errors" :key="index" color="error" icon="mdi-alert" :value="true">
        {{error}}
      </v-alert>

      <v-form ref="form">
        <v-layout row wrap class="ma-0">
          <v-subheader>@{{$store.state.auth.domain}}で利用するプロフィール</v-subheader>
          <v-flex xs12>
            <AppLabel required>名前</AppLabel>
            <v-text-field v-model.trim="newData.name" :rules="rules.name" :counter="20" label="名前" :disabled="disabled" required solo></v-text-field>
          </v-flex>

          <v-flex xs12 class="mb-4">
            <AppLabel noLabel>email</AppLabel>
            <div class="title">{{data.email}}</div>
          </v-flex>

          <v-flex xs12 class="mb-4">
            <AppLabel noLabel>役割</AppLabel>
            <div class="title">{{data.roles.admin?'管理者':''}}{{data.roles.editor?'メンバー':''}}</div>
          </v-flex>

          <v-flex xs12 class="text-xs-left">
            <v-btn @click="save" depressed color="primary" dark class="ml-0" :disabled="disabled">保存する</v-btn>
          </v-flex>

        </v-layout>
      </v-form>

      <v-snackbar v-model="snackbar.active" :color="snackbar.color">
        {{ snackbar.text }}
        <v-btn dark flat icon @click="snackbar.active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>

    </v-container>

  </div>
</template>

<script>
import Firebase from '@/mixins/firebase';
import Toolbar from '@/components/Toolbar.vue';
import AppLabel from '@/components/AppLabel.vue';

export default {
  mixins: [Firebase],
  components: { Toolbar, AppLabel },
  data() {
    return {
      unwatch: () => {},
      disabled: false,
      ref: null,
      initialized: { user: false },
      id: null,
      data: {},
      errors: [],
      newData: {},
      rules: {
        name: [
          v => !!v || '必須です',
          v => (v && v.length <= 20) || '20文字以下にして下さい',
        ],
      },
      hints: {},
      snackbar: { active: false, text: '', color: null },
    };
  },
  created() {
    this.unwatch = this.$store.watch(
      ({ auth }) => auth.data,
      data => {
        if (data.id) this.init(data);
      },
      { immediate: true }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    init(data) {
      this.id = data.id;
      this.data = data.data;
      this.ref = this.db
        .collection('teams')
        .doc(this.$store.state.auth.domain)
        .collection('users')
        .doc(this.id);
      this.newData = {
        name: this.data.name || '',
      };
      if (!this.initialized.user) this.initialized.user = true;
    },
    save() {
      // clean up
      this.errors = [];
      // validate
      if (!this.$refs.form.validate()) {
        this.errors.push('入力内容を確認してください');
        return;
      }
      // ui effect
      this.disabled = true;
      // prepare datas
      this.ref
        .update(this.newData)
        .then(() => {
          this.dialog = false;
          this.snackbar.text = '保存しました';
          this.snackbar.color = 'success';
          this.snackbar.active = true;
        })
        .catch(error => {
          this.errors.push(error.message || '予期せぬエラーが起きました。');
          this.snackbar.text = '保存に失敗しました';
          this.snackbar.color = 'error';
          this.snackbar.active = true;
        })
        .then(() => {
          this.disabled = false;
        });
    },
  },
};
</script>

