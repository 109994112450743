<template>
  <v-container fluid grid-list-lg>
    <v-alert v-for="(error,index) in errors" :key="index" color="error" icon="mdi-alert" :value="true">
      {{error}}
    </v-alert>

    <v-form ref="form">
      <v-layout row wrap class="my-4">
        <v-flex xs12 md8>

          <v-layout row wrap class="mb-0">
            <v-flex xs12>
              <AppLabel required>タイトル</AppLabel>
              <v-text-field v-model.trim="newData.title" :rules="rules.title" :counter="50" label="タイトル" :disabled="disabled" required solo></v-text-field>
            </v-flex>
            <v-flex xs12>
              <AppLabel>画像</AppLabel>
              <Uploader ref="uploader" :images="images" :hint="hints.images" :path="ref.path" :disabled="disabled" :limit="10"></Uploader>
            </v-flex>
            <v-flex xs12>
              <AppLabel required>サブタイトル</AppLabel>
              <v-text-field v-model.trim="newData.subtitle" :rules="rules.subtitle" :hint="hints.title" persistent-hint :counter="50" label="サブタイトル" :disabled="disabled" required solo></v-text-field>
            </v-flex>
            <v-flex xs12>
              <AppLabel required>テキスト</AppLabel>
              <v-textarea v-model.trim="newData.text" :rules="rules.text" :hint="hints.text" persistent-hint :counter="5000" label="テキスト" :disabled="disabled" required solo auto-grow rows=10></v-textarea>
            </v-flex>
            <v-subheader>SEO / ソーシャル</v-subheader>
            <v-flex xs12>
              <v-checkbox v-model="newData.noindex" label="検索エンジンにインデックスされないようにする" :disabled="disabled"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <AppLabel required>概要</AppLabel>
              <v-textarea v-model.trim="newData.metaDescription" :rules="rules.metaDescription" :hint="hints.metaDescription" persistent-hint :counter="100" label="概要" :disabled="disabled" required solo auto-grow rows=4></v-textarea>
            </v-flex>
            <v-flex xs12>
              <AppLabel>ハッシュタグ</AppLabel>
              <v-text-field v-model.trim="newData.hashtags" :rules="rules.hashtags" :hint="hints.hashtags" persistent-hint :counter="50" label="ハッシュタグ" :disabled="disabled" solo></v-text-field>
            </v-flex>
            <v-subheader>コメント</v-subheader>
            <v-flex xs12>
              <v-checkbox v-model="newData.commentable" label="コメントを有効にする" :disabled="disabled"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <AppLabel :required="newData.commentable">DISQUSショートネーム</AppLabel>
              <v-text-field v-model.trim="newData.disqusShortname" :rules="rules.disqusShortname" :hint="hints.disqusShortname" persistent-hint :counter="50" label="タイトル" :disabled="disabled" required solo></v-text-field>
            </v-flex>
          </v-layout>

        </v-flex>
        <v-flex xs12 md4>
          <AppLabel noLabel>公開 / 保存</AppLabel>
          <v-card>
            <v-container>
              <v-layout row wrap>
                <v-flex v-if="exists && data.public" xs12>
                  <a :href="`${$store.state.env.publicUrl}/v/${pageId}?utm_source=arfy&utm_medium=social&utm_campaign=1811`" target="_new" style="word-break:break-all;">{{this.$store.state.env.publicUrl}}/v/{{this.pageId}}</a>
                </v-flex>
                <v-flex v-if="exists" xs12 class="label">
                  <v-icon class="mr-2">mdi-clock-outline</v-icon>
                  {{formatDate(data.created)}}
                </v-flex>
                <v-flex v-if="exists" xs12 class="label">
                  <v-icon class="mr-2">mdi-update</v-icon>
                  {{formatDate(data.updated)}}
                </v-flex>
                <v-flex xs12>
                  <v-checkbox v-model="newData.public" label="公開する" :disabled="disabled"></v-checkbox>
                </v-flex>
                <v-flex xs12 class="text-xs-left">
                  <v-btn @click="save" depressed color="primary" dark class="ml-0" :disabled="disabled">保存する</v-btn>
                  <v-btn v-if="exists && !data.public" @click="preview" flat color="primary" class="ml-0" :disabled="disabled">プレビュー</v-btn>
                </v-flex>
                <v-flex v-if="exists" xs12 class="text-xs-left">
                  <v-btn @click="remove" flat color="error" class="ml-0" :disabled="disabled">削除</v-btn>
                </v-flex>

              </v-layout>
            </v-container>
          </v-card>

        </v-flex>
      </v-layout>

    </v-form>

    <v-snackbar v-model="snackbar.active" :color="snackbar.color">
      {{ snackbar.text }}
      <v-btn dark flat icon @click="snackbar.active = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

  </v-container>

</template>

<script>
import Firebase from '@/mixins/firebase';
import Xss from '@/mixins/xss';
import AppLabel from '@/components/AppLabel.vue';
import Uploader from '@/components/Uploader.vue';

export default {
  mixins: [Firebase, Xss],
  components: { AppLabel, Uploader },
  props: {
    pageId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      disabled: false,
      ref: null,
      exists: false,
      data: {},
      images: [],
      errors: [],
      newData: {},
      rules: {
        title: [
          v => !!v || '必須です',
          v => (v && v.length <= 50) || '50文字以下にして下さい',
        ],
        subtitle: [
          v => !!v || '必須です',
          v => (v && v.length <= 50) || '50文字以下にして下さい',
        ],
        text: [
          v => !!v || '必須です',
          v => (v && v.length <= 5000) || '5000文字以下にして下さい',
        ],
        metaDescription: [
          v => !!v || '必須です',
          v => (v && v.length <= 100) || '100文字以下にして下さい',
        ],
        hashtags: [v => !v || v.length <= 50 || '50文字以下にして下さい'],
        disqusShortname: [
          v =>
            (this.newData.commentable ? !!v : true) ||
            'コメントを有効にするには必須です',
          v => !v || v.length <= 50 || '50文字以下にして下さい',
        ],
      },
      hints: {
        images: '推奨サイズ：1200px X 900px以上 / アスペクト比4：3',
        metaDescription:
          '検索結果やシェアされた際の表示に使われます。ページの概要を記載して下さい。',
        hashtags:
          'twitterのシェアボタンがクリックされた際のデフォルトのハッシュタグに使われます。半角カンマで区切って入力して下さい。',
        // disqus shortname は admin > settings>general で確認できる
      },
      snackbar: { active: false, text: '', color: null },
    };
  },
  watch: {
    pageId: {
      handler() {
        this.init();
      },
      immediate: true,
    },
    data: {
      handler(data) {
        this.newData = {
          title: data.title || '',
          subtitle: data.subtitle || '',
          text: data.text || '',
          metaDescription: data.metaDescription || '',
          hashtags: data.hashtags || '',
          noindex: data.noindex || false,
          commentable: data.commentable || false,
          disqusShortname: data.disqusShortname || '',
          public: data.public || false,
        };
        const { exists } = this;
        this.$emit('input', { exists, data });
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      // stop listen
      this.unsubscribe();
      // reset data
      if (this.$refs.form) this.$refs.form.reset();
      // reset data object
      Object.assign(this.$data, this.$options.data.call(this));
      // set existence
      this.exists = !(this.pageId === 'new');
      // reset ref
      this.ref = this.exists
        ? this.db.collection('pages').doc(this.pageId)
        : this.db.collection('pages').doc();
      // load data
      if (this.exists) this.listen();
    },
    listen() {
      this.unsubscribes.push(
        this.ref.onSnapshot(
          doc => {
            if (doc.exists !== this.exists)
              throw new Error('Contradictory existence');
            this.data = doc.exists ? doc.data() : {};
          },
          error => {
            // eslint-disable-next-line
            console.log(error);
          }
        )
      );
      this.unsubscribes.push(
        this.ref
          .collection('images')
          .orderBy('index')
          .onSnapshot(
            querySnapshot => {
              const images = [];
              querySnapshot.forEach(doc => {
                images.push(doc);
              });
              this.images = images;
            },
            error => {
              // eslint-disable-next-line
              console.log(error);
            }
          )
      );
    },
    save() {
      // clean up
      this.errors = [];
      // validate
      if (!this.$refs.form.validate()) {
        this.errors.push('入力内容を確認してください');
        return;
      }
      // ui effect
      this.disabled = true;
      this.$store.commit('view/inprogress', true);
      // prepare datas
      const newData = this.exists
        ? Object.assign({}, this.newData, {
            updated: this.firebase.firestore.FieldValue.serverTimestamp(),
          })
        : Object.assign({}, this.newData, {
            created: this.firebase.firestore.FieldValue.serverTimestamp(),
            updated: this.firebase.firestore.FieldValue.serverTimestamp(),
            team: this.$store.state.auth.domain,
          });
      this.ref
        .set(newData, { merge: true })
        .then(() => this.$refs.uploader.upload())
        .then(() => {
          if (!this.exists) {
            this.$router.replace({
              name: 'Page',
              params: { pageId: this.ref.id },
            });
          }
          this.$nextTick(() => {
            this.snackbar.text = '保存しました';
            this.snackbar.color = 'success';
            this.snackbar.active = true;
          });
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error);
          this.errors.push('予期せぬエラーが起きました。');
          this.snackbar.text = '保存に失敗しました';
          this.snackbar.color = 'error';
          this.snackbar.active = true;
        })
        .then(() => {
          this.disabled = false;
          this.$store.commit('view/inprogress', false);
        });
    },
    remove() {
      if (!this.exists) return;
      // eslint-disable-next-line
      const result = window.confirm(`「${this.data.title}」を削除しますか？`);
      if (!result) return;
      this.ref
        .delete()
        .then(() => {
          this.$router.push({ name: 'Pages' });
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error);
          this.errors.push('予期せぬエラーが起きました。');
          this.snackbar.text = '削除に失敗しました';
          this.snackbar.color = 'error';
          this.snackbar.active = true;
        });
    },
    preview() {
      window.open('about:blank', 'preview');
      this.$store.state.auth.user.getIdToken(true).then(token => {
        document.cookie = `__session=${token};path=/p/;secure`;
        window.open(`/p/${this.pageId}`, 'preview');
      });
    },
  },
};
</script>

<style scoped>
.label {
  display: flex;
  align-items: center;
}
</style>
