import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import Admin from '@/Admin.vue';
import Public from '@/Public.vue';
import Page from '@/views/Page.vue';
import Pages from '@/views/Pages.vue';
import Users from '@/views/Users.vue';
import Profile from '@/views/Profile.vue';
import Settings from '@/views/Settings.vue';
import Signin from '@/views/Signin.vue';
import Signout from '@/views/Signout.vue';
import Top from '@/views/Top.vue';
import Forbidden from '@/views/Forbidden.vue';
import NotFound from '@/views/NotFound.vue';

Vue.use(Router);

const RouterClass = class {
  routerConfig() {
    const domain = this.domain || '';
    return {
      mode: 'history',
      base: process.env.BASE_URL,
      routes: [
        {
          path: `/t/${domain}/`,
          component: Admin,
          meta: { requiresAuth: true },
          children: [
            { path: '/', name: 'Pages', component: Pages },
            { path: 'users', name: 'Users', component: Users },
            {
              path: 'page/:pageId',
              name: 'Page',
              component: Page,
              props: true,
            },
            { path: 'profile', name: 'Profile', component: Profile },
            { path: 'settings', name: 'Settings', component: Settings },
            { path: 'signout', name: 'Signout', component: Signout },
          ],
        },
        {
          path: `/t/${domain}`,
          component: Public,
          meta: { requiresTeam: true },
          children: [{ path: 'signin', name: 'Signin', component: Signin }],
        },
        {
          path: `/t/${domain}`,
          component: Admin,
          meta: { requiresAuth: true },
          children: [
            {
              path: '*',
              component: NotFound,
              beforeEnter: (to, from, next) => {
                next({ name: 'Pages' });
              },
            },
          ],
        },
        {
          path: '/t/:domain/:some(.*)?',
          component: NotFound,
          beforeEnter: (to, from, next) => {
            if (RouterClass.validateDomain(to.params.domain))
              window.location.href = to.path;
            else next({ name: '404' });
          },
        },
        { path: '/', name: 'Top', component: Top },
        { path: '/403', name: '403', component: Forbidden },
        { path: '/404', name: '404', component: NotFound },
        { path: '*', component: NotFound },
      ],
    };
  }

  static validateDomain(domain) {
    if (typeof domain !== 'string') {
      return false;
    }
    const re = new RegExp(/^(?!.*--)[a-z0-9][a-z0-9-]{1,13}[a-z0-9]$/);
    const blacklist = ['403', '404'];
    return re.test(domain) && blacklist.indexOf(domain) === -1;
  }

  constructor() {
    const path = window.location.pathname.split('/').slice(1);
    if (path[0] === 't' && RouterClass.validateDomain(path[1])) {
      store.commit('auth/setDomain', { domain: path[1] });
      this.domain = store.state.auth.domain;
    } else {
      store.commit('auth/setDomain', { domain: null });
      this.domain = null;
    }

    const router = new Router(this.routerConfig());

    // auth conroll on state change
    store.watch(
      ({ auth }) => auth.user,
      user => {
        const { redirect } = router.currentRoute.query;
        if (user) {
          const to =
            redirect && redirect.startsWith(`/t/${this.domain}/`)
              ? redirect
              : { name: 'Pages' };
          router.push(to);
        } else {
          router.push({ name: 'Signin' });
        }
      }
    );

    // auth conroll on route
    router.beforeEach((to, from, next) => {
      const { user } = store.state.auth;
      // guard auth area
      if (to.matched.some(obj => obj.meta.requiresAuth)) {
        if (!user) {
          next({
            name: 'Signin',
            query: {
              redirect: to.fullPath,
            },
          });
          return;
        }
      }
      // guard signin from auth user
      if (to.name === 'Signin') {
        if (user) {
          next({ name: 'Pages' });
          return;
        }
      }
      next();
    });

    router.beforeEach((to, from, next) => {
      if (to.matched.some(obj => obj.meta.requiresTeam)) {
        const { domain } = store.state.auth;
        if (!domain) {
          next({ name: '404' });
          return;
        }
      }
      next();
    });

    store.dispatch('auth/observe');

    return router;
  }
};

export default new RouterClass();
