<template>
  <div class="page-container" style="height:100%;background-color: #ee44aa;">
    <v-container fluid fill-height style="height:100%;">
      <v-layout row justify-center align-center wrap>
        <v-flex xs10 sm8 md6 lg4 class="text-xs-center">
          <h2 class="display-1 mb-4 white--text">Select Team !</h2>
          <v-form @submit.prevent="go">
            <v-text-field v-model.trim="team" outline label="Team" type="text" dark color="accent">
              <div slot="prepend" class="text-xs-right" style="width:52px;padding-right:8px;">
                <v-icon>mdi-domain</v-icon>
              </div>
              <div slot="append-outer" style="margin-top:-10px;">
                <v-btn icon @click="go">
                  <v-icon left>mdi-arrow-right-thick</v-icon>
                </v-btn>
              </div>
            </v-text-field>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      team: '',
    };
  },
  methods: {
    go() {
      if (this.team) window.location.href = `/t/${this.team}/`;
    },
  },
};
</script>

