import { render, staticRenderFns } from "./PageEdit.vue?vue&type=template&id=cf838a64&scoped=true&"
import script from "./PageEdit.vue?vue&type=script&lang=js&"
export * from "./PageEdit.vue?vue&type=script&lang=js&"
import style0 from "./PageEdit.vue?vue&type=style&index=0&id=cf838a64&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf838a64",
  null
  
)

component.options.__file = "PageEdit.vue"
export default component.exports