<template>
  <label class="app-label">
    <slot></slot>
    <template v-if="noLabel"></template>
    <v-chip v-else-if="required" label small color="error" text-color="white">必須</v-chip>
    <v-chip v-else label small color="warning" text-color="white">任意</v-chip>
  </label>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>

<style scoped>
.app-label {
  display: flex;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  height: 24px;
  line-height: 24px;
  margin-bottom: 4px;
}
.v-chip {
  margin: 0;
  margin-left: 4px;
}
</style>

