<template>
  <v-container fluid fill-height>
    <v-layout column align-center justify-center>
      <h3 class="display-3">Now Signing Out</h3>
      <span class="subheading">Please wait a moment.</span>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Signout',
  created() {
    return this.$store.dispatch('auth/signout');
  },
};
</script>