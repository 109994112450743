<template>
  <div class="page-container">

    <Toolbar :title="'ページの編集'" :subtitle="pageId==='new'?'新しいページ':page.data.title" :tabs="true">
      <v-layout slot="extension">
        <v-tabs v-model="tab" color="transparent">
          <v-tab href="#page">
            PAGE
          </v-tab>
          <v-tab href="#qr" v-if="page.exists">
            QR
          </v-tab>
        </v-tabs>
      </v-layout>
    </Toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item id="page">
        <PageEdit v-model="page" :pageId="pageId"></PageEdit>
      </v-tab-item>

      <v-tab-item id="qr" v-if="page.exists">
        <QR :pageId="pageId" :active="tab==='qr'"></QR>
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import PageEdit from '@/components/PageEdit.vue';
import QR from '@/components/QR.vue';

export default {
  components: { Toolbar, PageEdit, QR },
  props: {
    pageId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.tab = this.$route.hash.replace(/#/g, '');
  },
  data() {
    return {
      tab: null,
      page: { exists: false, data: {} },
    };
  },
};
</script>
