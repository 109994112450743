<template>
  <v-content class="content">
    <v-container fluid fill-height>
      <v-layout column align-center justify-center>
        <h2 class="title white--text mb-2">{{$store.state.auth.domain}}にログイン</h2>
        <button class="signin-btn" @click="signin">&nbsp;</button>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
export default {
  methods: {
    signin() {
      return this.$store.dispatch('auth/signin');
    },
  },
};
</script>

<style scoped>
.content {
  background-color: #ee44aa;
}
.signin-btn {
  height: 46px;
  line-height: 46px;
  width: 191px;
  background: no-repeat
    url('../assets/images/signin-assets/btn_google_signin_light_normal_web.png');
  text-decoration: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  outline: none;
}
.signin-btn:focus {
  background: no-repeat
    url('../assets/images/signin-assets/btn_google_signin_light_focus_web.png');
}
.signin-btn:active {
  background: no-repeat
    url('../assets/images/signin-assets/btn_google_signin_light_pressed_web.png');
}
.signin-btn:disabled {
  background: no-repeat
    url('../assets/images/signin-assets/btn_google_signin_light_disabled_web.png');
}
</style>
