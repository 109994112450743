<template>
  <v-container fluid grid-list-md>
    <v-layout>
      <v-flex xs12>
        <v-card>
          <v-layout class="ma-0">
            <v-flex xs4 md3>
              <v-img ref="defaultQR" :src="makeQR()" aspect-ratio="1" @click="downloadQR('defaultQR')">
                <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                  <v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
                </v-layout>
              </v-img>
            </v-flex>

            <v-flex xs8 md9 :style="{padding:padding + 'px'}">
              <v-layout column fill-height justify-space-between class="ma-0">
                <div>
                  <h3 class="title">QRコード</h3>
                </div>
                <div>
                  <v-btn depressed :small="!$vuetify.breakpoint.lgAndUp" block class="ma-0 my-1" @click="downloadQR(2)">ダウンロード 小</v-btn>
                  <v-btn depressed :small="!$vuetify.breakpoint.lgAndUp" block class="ma-0 my-1" @click="downloadDefaultQR('defaultQR')">ダウンロード 大</v-btn>
                </div>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import qrcode from 'qrcode-generator';
import Firebase from '@/mixins/firebase';

export default {
  mixins: [Firebase],
  props: {
    pageId: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      typeNumber: 6,
      cellCount: 41, // 4:33, 5:37, 6:41
      size: 10,
      padding: 8,
      qr: null,
    };
  },
  computed: {
    url() {
      return `${this.$store.state.env.publicUrl}/v/${this.pageId}?utm_source=qr&utm_medium=display&utm_campaign=1811`;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    pageId: {
      handler() {
        this.makeQR();
      },
      immediate: true,
    },
    active: {
      handler(active) {
        if (active) this.$nextTick(this.handleResize);
      },
      immediate: true,
    },
  },
  methods: {
    handleResize() {
      const qrHeight = this.$refs.defaultQR.$el.clientHeight;
      this.padding = (qrHeight * 4) / this.cellCount;
    },
    makeQR() {
      const errorCorrectionLevel = 'L';
      const qr = qrcode(this.typeNumber, errorCorrectionLevel);
      qr.addData(this.url);
      qr.make();
      this.qr = qr;
      return qr.createDataURL(this.size);
    },
    downloadDefaultQR(ref) {
      const { src } = this.$refs[ref];
      const a = document.createElement('a');
      a.href = src;
      if (typeof a.download !== 'undefined') {
        const pix = (this.cellCount + 8) * this.size;
        a.setAttribute('download', `qr_page=${this.pageId}_${pix}x${pix}.gif`);
      } else {
        a.setAttribute('target', 'new');
      }
      a.click();
    },
    downloadQR(size) {
      const a = document.createElement('a');
      a.href = this.qr.createDataURL(size);
      if (typeof a.download !== 'undefined') {
        const pix = (this.cellCount + 8) * size;
        a.setAttribute('download', `qr_page=${this.pageId}_${pix}x${pix}.gif`);
      } else {
        a.setAttribute('target', 'new');
      }
      a.click();
    },
  },
};
</script>

<style scoped>
.v-image {
  cursor: pointer;
}
</style>


