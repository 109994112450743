/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
const LT = /</g;
const QUOT = /"/g;
const SQUOT = /'/g;
const AMP = /&/g;
const NULL = /\x00/g;
const SPECIAL_ATTR_VALUE_UNQUOTED_CHARS = /(?:^$|[\x00\x09-\x0D "'`=<>])/g;
const SPECIAL_HTML_CHARS = /[&<>"'`]/g;
const SPECIAL_COMMENT_CHARS = /(?:\x00|^-*!?>|--!?>|--?!?$|\]>|\]$)/g;
/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */

const strReplace = (s, regexp, callback) => {
  let r = '';
  if (s === undefined) r = 'undefined';
  else if (s === null) r = 'null';
  else r = s.toString().replace(regexp, callback);
  return r;
};

export default {
  methods: {
    test(s) {
      return strReplace(s, AMP, '&amp;');
    },
  },
};
