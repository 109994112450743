import firebase from '@/vendor/firebase';

const getRolesWithTimer = user => {
  let unsubscribe = () => {};
  return new Promise((resolve, reject) => {
    setTimeout(reject, 15000, new Error('timeout'));
    const docRef = firebase
      .firestore()
      .collection('users')
      .doc(user.email)
      .collection('uids')
      .doc(user.uid);
    unsubscribe = docRef.onSnapshot(doc => {
      if (!doc.exists) return null;
      return user
        .getIdTokenResult(true)
        .then(({ claims }) => {
          resolve(claims.roles);
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error);
        });
    });
  })
    .then(roles => {
      unsubscribe();
      return roles;
    })
    .catch(error => {
      unsubscribe();
      return Promise.reject(error);
    });
};

export default {
  namespaced: true,
  state: {
    requiredRoles: ['admin', 'editor'],
    domain: null,
    team: null,
    user: null,
    data: { id: null, data: null },
    roles: null,
    authenticated: false,
  },
  mutations: {
    setDomain(state, { domain }) {
      state.domain = domain;
    },
    setTeam(state, payload) {
      state.team = payload.team;
    },
    setUser(state, payload) {
      state.user = payload.user;
      state.roles = payload.roles;
      if (!state.authenticated) state.authenticated = true;
    },
    setUserData(state, payload) {
      state.data = payload;
    },
    setRequiredRoles(state, payload) {
      state.requiredRoles = payload.roles;
    },
  },
  actions: {
    observe({ state, commit }) {
      const { domain } = state;
      firebase.auth().onAuthStateChanged(user => {
        if (user && domain) {
          user
            .getIdTokenResult(true)
            .then(({ claims }) => {
              if (claims.roles) return claims.roles;
              throw new Error('user is not initialized');
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error);
              return getRolesWithTimer(user);
            })
            .then(roles => {
              if (!roles || !roles[domain])
                throw new Error('user is not initialized');
              const result = state.requiredRoles.some(
                role => roles[domain][role] || false
              );
              if (!result) throw new Error('permission error');
              return roles[domain];
            })
            .then(roles => {
              commit('setUser', { user, roles });
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error);
              commit('setUser', { user: null, roles: null });
            });
        } else {
          // eslint-disable-next-line
          console.log('no user');
          commit('setUser', { user: null, roles: null });
        }
      });
    },
    signin({ state }, payload) {
      const { user } = state;
      if (user) {
        return true;
      }
      const provider = new firebase.auth.GoogleAuthProvider();
      if (payload && payload.email) {
        provider.setCustomParameters({ login_hint: payload.email });
      }
      return firebase
        .auth()
        .signInWithRedirect(provider)
        .catch(error => {
          // eslint-disable-next-line
          console.log(error);
          // eslint-disable-next-line
          alert(
            'エラーが起きました。ページをリロードして再度サインインを試してください'
          );
        });
    },
    signout() {
      return firebase.auth().signOut();
    },
    listen({ state, commit }, { user }) {
      if (!user) {
        // data=nullのコミット
        return;
      }
      let first = true;
      firebase
        .firestore()
        .collection('teams')
        .doc(state.domain)
        .collection('users')
        .where('email', '==', user.email)
        .limit(1)
        .onSnapshot(
          snapshot => {
            if (snapshot.empty) {
              commit('setUserData', {
                id: null,
                data: null,
              });
              return;
            }
            const doc = snapshot.docs[0];
            commit('setUserData', { id: doc.id, data: doc.data() });
            if (
              !doc.metadata.hasPendingWrites &&
              !doc.get('created') &&
              first
            ) {
              first = false;
              doc.ref.update({
                created: firebase.firestore.FieldValue.serverTimestamp(),
              });
            }
          },
          error => {
            commit('setUserData', {
              id: null,
              data: null,
            });
            // eslint-disable-next-line
            console.log(error);
          }
        );
    },
  },
};
