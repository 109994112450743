<template>
  <div>
    <v-navigation-drawer fixed :clipped="$vuetify.breakpoint.lgAndUp" app v-model="drawer" class="grey lighten-4">

      <v-toolbar flat class="transparent">
        <v-btn outline block color="primary" :to="{ name: 'Page', params: { pageId: 'new' }}">ページを追加</v-btn>
      </v-toolbar>

      <v-divider></v-divider>
      <v-list dense>
        <template v-for="(item, index) in items">
          <v-divider v-if="item.divider" :key="index"></v-divider>
          <v-list-group v-else-if="item.children" v-model="item.model" :key="item.text" :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="">
            <v-list-tile slot="activator">
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ item.text }}
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile v-for="(child, i) in item.children" :key="i" @click="true">
              <v-list-tile-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ child.text }}
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group>
          <v-list-tile v-else :key="item.text" :to="item.to" exact>
            <v-list-tile-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>
                {{ item.text }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar color="primary" dark app :clipped-left="$vuetify.breakpoint.lgAndUp" fixed>
      <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      <h1 class="subheading">
        ARFY - @{{$store.state.auth.domain}}
      </h1>

      <v-spacer></v-spacer>

      <v-menu bottom left light>
        <v-btn slot="activator" dark icon>
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>

        <v-list two-line>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{$store.state.auth.data.data?$store.state.auth.data.data.name:''}}</v-list-tile-title>
              <v-list-tile-sub-title>
                <AppLink :to="{name:'Profile'}">プロフィールを編集</AppLink>
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>@{{$store.state.auth.domain}}</v-list-tile-title>
              <v-list-tile-sub-title v-if="$store.state.auth.roles.admin">
                <AppLink :to="{name:'Users'}">チームを管理</AppLink>
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-tile :to="{name:'Signout'}">
            <v-list-tile-avatar>
              <v-icon>mdi-logout</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-title>サインアウト</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-content>
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </v-content>
  </div>
</template>

<script>
import AppLink from '@/components/AppLink.vue';

export default {
  components: { AppLink },
  data: () => ({
    dialog: false,
    drawer: null,
    items: [
      {
        icon: 'mdi-view-list',
        text: 'ページ一覧',
        to: { name: 'Pages' },
      },
      {
        icon: 'mdi-account-multiple',
        text: 'チーム管理',
        to: { name: 'Users' },
      },
      /*
      {
        icon: 'mdi-settings',
        text: 'チーム設定',
        to: { name: 'Settings' },
      },
      */
      {
        icon: 'mdi-account',
        text: 'プロフィール',
        to: { name: 'Profile' },
      },
    ],
  }),
};
</script>
